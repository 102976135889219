import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navigation/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navigation/ModeToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navigation/NavbarDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navigation/NavbarMobile.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/UserNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarNav"] */ "/vercel/path0/components/ui/Sidebar-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Root","displayName","List","Item","Trigger","Content","Link","Viewport","Indicator"] */ "/vercel/path0/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
