"use client"

import Link from "next/link"
import ThemeImage from "@/components/ThemeImage"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { routeList, SNSList } from "@/config/routes"
import {
  TableOfContents,
  Newspaper,
  Book,
  Archive,
  Calculator,
  FileText,
  Database,
  BellRing,
  Mail,
  Scale,
  FileQuestion,
  Shield,
  Link as LinkIcon,
  NotebookPen,
  Handshake,
  BadgeJapaneseYen,
} from "lucide-react"

// アイコンのマッピング
const iconMapping: Record<string, React.ElementType> = {
  TableOfContents,
  Newspaper,
  Book,
  Archive,
  Calculator,
  FileText,
  Database,
  BellRing,
  Mail,
  Scale,
  FileQuestion,
  Shield,
  Link: LinkIcon,
  NotebookPen,
  Handshake,
  BadgeJapaneseYen,
}

const Footer = () => {
  return (
    <footer
      id="footer"
      className="container mx-auto sticky top-full my-10 px-2"
    >
      <Separator className="" />
      <section className="py-4 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-x-12 gap-y-8">
        <div className="col-span-full xl:col-span-1">
          <Link href="/" className="">
            <ThemeImage className="" />
          </Link>
          <p className="text-sm mt-2 pl-2">構造設計に関わる人のための</p>
          <p className="text-sm mt-1 pl-2">実験的コミュニティ</p>
          <div className="flex gap-4 pl-2 pt-4">
            {SNSList.map((sns, index) => (
              <Link
                key={index}
                href={sns.href}
                target="_blank"
                className="transition duration-100 hover:text-primary/60 active:text-primary/60"
                aria-label={sns.label}
                dangerouslySetInnerHTML={{ __html: sns.svg }}
              />
            ))}
          </div>
        </div>

        {routeList.map((route, index) => {
          const IconComponent =
            iconMapping[route.icon as keyof typeof iconMapping]

          return (
            <div key={index} className="flex flex-col gap-2 pt-1">
              <h3 className="font-bold text-lg mb-1 ml-2 flex items-center gap-2">
                {IconComponent && <IconComponent className="h-5 w-5" />}
                {route.label}
              </h3>
              <Separator className="mb-1" />
              <ul>
                {route.items?.map((item, subIndex) => {
                  const ItemIconComponent =
                    iconMapping[item.icon as keyof typeof iconMapping]

                  return (
                    <li key={subIndex}>
                      <Link href={item.href}>
                        <Button
                          variant="ghost"
                          className="w-full justify-start text-sm flex items-center gap-2"
                        >
                          {ItemIconComponent && (
                            <ItemIconComponent className="h-4 w-4" />
                          )}
                          {item.label}
                        </Button>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </section>
    </footer>
  )
}

export default Footer
